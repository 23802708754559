@import url('../BaseStyle.css');

@media (min-width: 1025px){

    #loading{
        display: flex;
        flex-grow: 1;
        width: 0;
        justify-content: center;
        background-color: var(--color10);
        padding: 20vw 0;
    }

    #loading-img{
        height: 2vw;
    }
}