@import url('../BaseStyle.css');
@media (max-width: 1024px){

  #main-body{
    display: block;
    position: relative;
    width: 100vw;
  }

  #go-to-top-button{
    display: flex;
    z-index: 6;
    cursor: pointer;
    position: fixed;
    right: 2vw;
    bottom: 2vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  #go-to-top-button img{
    height: 3rem;
  }

  #promo-container-desktop{
    display: none;
  }

  #promo-container-mobile{
    display: flex;
    width: 100%;
    height: 22vw;
    max-height: 6rem;
  }
  
  .promo-image{
      object-fit: cover;
      width: 100%;
      height: 100%;
  }
}