@import url('../BaseStyle.css');

@media (max-width: 1024px){

    #loading{
        display: flex;
        width: 100vw;
        justify-content: center;
        background-color: var(--color10);
        padding: 20vw 0;
    }

    #loading-img{
        height: 10vw;
    }
}