@import url('../BaseStyle.css');
@media (min-width: 1025px){

    #main-body{
      display: flex;
      flex-direction: row;
      align-items: stretch;
      position: relative;
      width: 100%;
    }
  
    #go-to-top-button{
      display: flex;
      z-index: 6;
      cursor: pointer;
      position: fixed;
      right: 2vw;
      bottom: 12vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    #go-to-top-button img{
      height: 3rem;
    }

    #promo-container-desktop{
      display: flex;
      width: 100%;
      height: 8rem;
    }

    #promo-container-mobile{
      display: none;
    }

    .promo-image{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
  }