@import url('../BaseStyle.css');

@media (max-width: 1024px){

    @keyframes x-in {
        from {
            left: -100vw;
        }
        to {
            left: 0;
        }
    }
    
    @keyframes x-out {
        from {
            left: 0;
        }
        to {
            left: -100vw;
        }
    }

    #menu{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        min-width: 18rem;
        max-width: 30rem;
        height: 100vh;
        position: fixed;
        top: 0;
        left: -100vw;
        background-color: var(--color10);
        z-index: 9;
    }

    #menu-body{
        display: flex;
        padding: 2vw 5vw;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
    }

    #menu-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    #menu-cross{
        width: 1.5rem;
    }

    #menu-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 90%;
        padding-bottom: 20vh;
    }

    #menu-special-collection-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .menu-special-collection{
        display: flex;
        padding: 0.5rem 0;
        box-sizing: border-box;
        margin-bottom: 0.5rem;
        border-radius: 0.2rem; /*TEST border radius mobile*/
    }

    .menu-special-collection-label{
        color: var(--color8);
        font-family: 'Now-Medium';
        font-size: 1.2rem;
        margin: 0;
    }

    .menu-title{
        color: var(--color6);
        font-size: 1.3rem;
        margin: 5vw 0;
        font-family: 'Now';
    }

    .menu-subtitle{
        color: var(--color6);
        font-size: 1rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .menu-item{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .menu-item-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 3vw 0;
        box-sizing: border-box;
        width: 100%;
    }

    .menu-item-header-container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .menu-item-header-img-container{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 15vw;
        max-width: 4rem;
    }

    .menu-item-header-img{
        height: 1.8rem;
    }

    .menu-item-header-name{
        color: var(--color6);
        font-size: 1rem;
        margin: 0;
    }

    .menu-item-header-arrow{
        height: 0.5rem;
    }

    .menu-item-separator{
        background-color: var(--color6);
        height: 0.2rem;
        border-radius: 0.2rem; /*TEST border radius desktop*/
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .menu-item-content{
        display: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
    }

    .menu-item-content-item{
        color: var(--color6);
        font-size: 1rem;
        padding: 1vw;
        margin: 1vw;
        background-color: var(--color10);
        border-radius: 0.2rem; /*TEST border radius mobile*/
        box-sizing: border-box;
        width: 100%;
    }

    .menu-item-content-item-selected{
        color: var(--color6);
        font-size: 1rem;
        padding: 1vw;
        margin: 1vw;
        background-color: var(--color5);
        border-radius: 0.2rem; /*TEST border radius mobile*/
        box-sizing: border-box;
    }

    #menu-price-slider-container{
        width: 80%;
        margin-left: 10%;
        max-width: 15rem;
    }

    #menu-price-slider{
        display: flex;
        position: relative;
        border: 0;
        border-radius: 0;
        padding: 0 0.4rem;
        box-sizing: border-box;
        box-shadow: none;
        flex-direction: column;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        margin: 1rem 0;
    }

    #menu-price-slider .bar-inner {
        background-color: var(--color6);
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: space-between;
        position: relative;
        border: 0;
        justify-content: space-between;
        box-shadow: none;
        height: 0.3rem;
    }

    #menu-price-slider .thumb {
        background-color: var(--color6);
    }

    #menu-price-slider .thumb::before {
        content: '';
        border: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: none;
        border-radius: 0;
        /*z-index: 10; /* valutare */ 
        margin: 0;
        height: 1.5rem;
        width: 0.8rem;
        border-radius: 0.2rem;
    }

    #menu-price-slider .bar {
        height: 0.3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #menu-price-slider .bar-right {
        width: 25%;
        background-color: var(--color6);
        border-radius: 0.5vw;
        box-shadow: none;
        opacity: 0.3;
        height: 0.3rem;
        padding: 0;
    }

    #menu-price-slider .bar-left {
        width: 25%;
        background-color: var(--color6);
        border-radius: 0.5vw;
        box-shadow: none;
        opacity: 0.3;
        height: 0.3rem;
        padding: 0;
    }

    #menu-price-slider .ruler {
        display: none;
    }

    #menu-price-slider .label {
        display: none;
    }

    #menu-price-slider .thumb .caption * {
        display: none;
    }

    #menu-price-input-container{
        margin-left: 0.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .menu-price-input{
        font-size: 1rem;
        font-family: 'Now-Regular';
        color: var(--color6);
        background-color: var(--color10);
        border-radius: 0.5rem;
        height: 2rem;
        border: 0.15rem solid var(--color6);
    }

    #menu-sorting-container{       
        display: flex;
        flex-direction: column;
        margin: 2rem 0;
        align-items: flex-end;
    }

    #menu-sorting-label{
        color: var(--color6);
        font-weight: bold;
        font-size: 1rem;
        margin: 0;
        margin-right: 1rem;
    }

    #menu-sorting-input-container{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #menu-sorting-input{
        display: flex;
        align-items: center;
        border-radius: 0.2rem; /*TEST border radius mobile*/
        border: 0.2rem solid var(--color6);
        padding: 1vw;
        width: 40vw;
        max-width: 10rem;
        min-width: 8rem;
        justify-content: space-between;
        box-sizing: border-box;
    }

    #menu-sorting-icon{
        width: 0.6rem;
    }

    #menu-sorting-selected{
        font-size: 1rem;
        font-weight: bold;
        color: var(--color6);
        margin: 0;
        margin-right: 1rem;
    }

    #menu-sorting-list{
        display: flex;
        background-color: var(--color10);
        border-radius: 0.2rem; /*TEST border radius mobile*/
        border: 0.2rem solid var(--color6);
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
    }

    #menu-sorting-list label{
        color: var(--color6);
        font-weight: bold;
        background-color: var(--color10);
        font-size: 1rem;
        margin: 1vw 0;
        margin-left: 0.5rem;
    }

    #menu-sorting-input option:hover{
        background-color: var(--color6);
        color: var(--color10);
    }

    #menu-buttons-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .menu-button{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--color8);
        padding: 0.8rem;
        margin-top: 1rem;
        box-sizing: border-box;
        border-radius: 0.2rem; /*TEST border radius mobile*/
        
    }

    .menu-button-label{
        color: var(--color10);
        font-size: 1rem;
        margin: 0;
        font-family: 'Now-Medium';
    }

    #menu-content::-webkit-scrollbar {
        height: 0 !important;
        width: 0 !important;
    }
}
