@import url('../BaseStyle.css');

@media (max-width: 1024px){

    @keyframes search-open {
        from {
            height: 0;
        }
        to {
            height: 4rem;
        }
        0%{
            overflow: hidden;
        }
        99%{
            overflow: hidden;
        }
        100%{
            overflow: visible;
        }
    }
    
    @keyframes search-close {
        from {
            height: 4rem;
        }
        to {
            height: 0;
        }
        0%{
            overflow: hidden;
        }
        100%{
            overflow: hidden;
        }
    }
    
    #nav{
        background-color: var(--color1);
        z-index: 4;
        width: 100vw;
    }

    #nav-container0{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 10vh;
    }

    #nav-container-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin: 1rem 0;
    }

    #nav-container2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-button-container-sx, .nav-button-container-dx{
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 4.5rem;
        justify-content: center;
    }

    #nav-search-img{
        height: 1.5rem;
    }

    #nav-cart-container{
        display: flex;
        margin-left: 1rem;
        position: relative;
    }

    #nav-cart-img{
        width: 1.8rem;
        margin-bottom: 0.2rem;
    }

    #nav-cart-counter{
        text-decoration: none;
        font-size: 1rem;
        color: var(--color1);
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    #nav-menu-img{
        height: 1.5rem;
    }

    #nav-title{
        margin: 0;
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
    }

    #nav-subtitle{
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }

    #nav-pages{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 1rem 0;
    }

    #nav-page-link-rem{
        display: none;
    }

    .nav-page-link{
        text-decoration: none;
        width: 6rem;
        text-align: center;
        margin: 0;
    }

    .nav-page-label{
        color: var(--color10);
        font-size: 1rem;
        font-weight: normal;
    }

    /* nav search */

    #nav-search-container{
        display: flex;
        position: relative;
        height: 0;
        overflow: hidden;
    }

    #nav-search-container1{
        display: flex;
        position: relative;
        align-items: center;
        height: 4rem;
        overflow: hidden;
    }

    #nav-search-input{
        color: var(--color6);
        background-color: var(--color10);
        border-radius: 1rem;
        width: 15rem;
        font-size: 1rem;
        height: 2.2rem;
        padding: 0.2rem;
        padding-right: 1rem;
        box-sizing: border-box;
    }

    #nav-search-cross{
        height: 0.8rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1vw;
    }

    #nav-search-results-container{
        display: flex;
        position: absolute;
        top: 4rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--color10);
        flex-direction: column;
        align-items: center;
        z-index: 5;
        border-radius: 0.2rem; /*TEST border radius mobile*/
        width: 90vw;
        border: 0.2rem solid var(--color5);
    }

    .nav-search-results-link{
        display: flex;
        margin: 0;
        align-items: center;
        flex-direction: row;
        border-top: 0.1rem solid var(--color9);
        padding: 0.5rem;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
    }

    .nav-search-results-label{
        text-align: center;
        font-size: 0.8rem;
        font-weight: normal;
        padding: 0.5rem;
        box-sizing: border-box;
        color: var(--color6);
        margin: 0;
    }

    .nav-search-results-image{
        height: 3rem;
        border-radius: 0.5vw;
    }
}
