
@font-face {
    font-family: 'Now';
    src: url('now.bold.otf') format('opentype');
}

@font-face {
    font-family: 'Now-Medium';
    src: url('now.medium.otf') format('opentype');
}

@font-face {
    font-family: 'Now-Regular';
    src: url('now.regular.otf') format('opentype');
}
  

:root {
    /*tenute*/
    --color1: #455727;
    --color2: #7d253a;
    --color3: #a85163;
    --color4: #c29720;
    --color5: #faefe0;

    /*enoteca*/
    --color6: #000000;
    --color7: #502523;
    --color8: #82161b;
    --color9: #edd0b1;
    
    --color10: #ffffff;
    --color11: #ffcd36;
 
}

label, a{
    margin: 0;
    color: var(--color6);
    text-decoration: none;
    font-family: 'Now-Regular';
    white-space: pre-wrap;
}

p{
    color: var(--color6);
    font-family: 'Now-Regular';
    margin: 0;
}

h1{
    color: var(--color6);
    font-family: 'Now';
    font-weight: normal;
    margin: 0;
}

h2{
    color: var(--color6);
    font-family: 'Now-Medium';
    font-weight: normal;
    margin: 0;
}

select, input {
    margin: 0;
    color: var(--color1);
    font-family: 'Now-Regular';
    user-select: none;
    -webkit-user-select: none;
}

button{
    margin: 0;
    font-family: 'Now-Regular';
    border: 0 transparent;
    border-radius: 0.2rem; /*TEST border radius desktop*/
    font-weight: bold;
    
    color: var(--color10);
    font-size: 1rem;
    background-color: var(--color9);
}

button, input{
    border: 0;
}

button:active, button:focus, input:focus, input:active{
    outline: 0;
}

#blocker{
    width: 100vw;
    height: 100vh; 
    background-color: black;
    opacity: 0.4;
    position: fixed; 
    top: 0;
    z-index: 10; 
    display: none;
}

#blocker-menu{
    width: 100%;
    height: 100%; 
    background-color: black;
    opacity: 0.4;
    position: fixed; 
    top: 0;
    z-index: 8; 
    display: none;
}

@keyframes open-popup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    0% {
       display: flex;
    }
}

@keyframes close-popup {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
    100% {
        display: none;
    }
}


@media (min-width: 1025px){
    body::-webkit-scrollbar {
        /*display: none; /* Chrome */
    }

    body {
        /*-ms-overflow-style: none;  /* IE and Edge */
        /*scrollbar-width: none;  /* Firefox */
        background-color: var(--color10);
    }
}

@media (max-width: 1024px){

    body::-webkit-scrollbar {
        display: none; /* Chrome */
    }

    body {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: var(--color10);
    }
}