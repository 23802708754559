@import url('../BaseStyle.css');

@media (min-width: 1025px){
    
    @keyframes search-open {
        from {
            height: 0;
        }
        to {
            height: 4rem;
        }
        0%{
            overflow: hidden;
        }
        99%{
            overflow: hidden;
        }
        100%{
            overflow: visible;
        }
    }
    
    @keyframes search-close {
        from {
            height: 4rem;
        }
        to {
            height: 0;
        }
        0%{
            overflow: hidden;
        }
        100%{
            overflow: hidden;
        }
    }

    #nav{
        background-color: var(--color1);
        z-index: 4;
        width: 100%;
    }

    #nav-container0{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 10vh;
    }

    #nav-container-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin: 2rem 0;
    }

    #nav-container2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-button-container-sx{
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 30%;
        justify-content: flex-start;
    }

    .nav-button-container-dx{
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 30%;
        justify-content: flex-end;
    }

    #nav-search-img{
        height: 2rem;
        margin: 0 1rem;
        cursor: pointer;
    }

    #nav-cart-container{
        display: flex;
        position: relative;
        cursor: pointer;
    }

    #nav-cart-img{
        height: 2rem;
    }

    #nav-cart-counter{
        text-decoration: none;
        font-size: 1rem;
        color: var(--color1);
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        cursor: pointer;
    }

    #nav-menu-img{
        height: 2rem;
        cursor: pointer;
    }

    #nav-title{
        font-weight: bold;
        font-size: 2.5rem;
        cursor: pointer;
        text-align: center;
        margin: 0;
    }

    #nav-subtitle{
        font-size: 2rem;
        font-weight: normal;
        cursor: pointer;
        margin: 0;
    }

    #nav-pages{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    #nav-page-link-rem{
        display: flex;
    }
    
    .nav-page-link{
        text-decoration: none;
        text-align: center;
        margin: 0 1.5rem;
    }

    .nav-page-label{
        color: var(--color10);
        font-size: 1.2rem;
        font-weight: normal;
        cursor: pointer;
        margin: 0;
    }

    /* nav search */

    #nav-search-container{
        display: flex;
        position: relative;
        height: 0;
        overflow: hidden;
    }

    #nav-search-container1{
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    #nav-search-input{
        color: var(--color6);
        background-color: var(--color10);
        border-radius: 1rem;
        width: 25vw;
        max-width: 20rem;
        height: 2.2rem;
        padding: 0.2rem;
        padding-right: 1rem;
        box-sizing: border-box;
        font-size: 1.2rem;
        font-family: 'Now-Regular';
    }

    #nav-search-cross{
        height: 0.8rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1vw;
        cursor: pointer;
    }

    #nav-search-results-container{
        display: flex;
        position: absolute;
        /*top: 6rem;*/
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--color10);
        flex-direction: column;
        align-items: center;
        z-index: 5;
        border-radius: 0.2rem; /*TEST border radius desktop*/
        width: 50vw;
        border: 0.2vw solid var(--color5);
    }

    .nav-search-results-link{
        display: flex;
        margin: 0;
        align-items: center;
        flex-direction: row;
        border-top: 0.1rem solid var(--color9);
        padding: 0.5rem;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
    }

    .nav-search-results-label{
        text-align: center;
        font-size: 1rem;
        font-weight: normal;
        padding: 0.5rem;
        box-sizing: border-box;
        color: var(--color6);
        margin: 0;
        cursor: pointer;
    }

    .nav-search-results-image{
        height: 4rem;
        border-radius: 0.2rem; /*TEST border radius desktop*/
    }
}
