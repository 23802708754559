@import url('../BaseStyle.css');

@media (max-width: 1024px){

    #footer{
        display: block;
        width: 100vw;
        position: relative;
    }

    #footer-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        box-sizing: border-box;
    }

    #footer-body{
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
    }

    #footer-contacts{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-section-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 1rem 0;
    }

    .footer-section-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem 0;
    }

    .footer-section-logo-container{
        display: flex;
        width: 6rem;
        flex-direction: column;
        align-items: center;
    }

    #footer-section-logo1{
        height: 4rem;
        margin: 0.5rem 0;
    }

    #footer-section-logo2{
        height: 5rem;
    }

    .footer-section-title{
        font-weight: bold;
        font-size: 1.2rem;
        color: var(--color10);
        margin: 0;
    }

    .footer-social-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem 0;
    }

    .footer-social{
        display: block;
        cursor: pointer;
        margin: 0 0.5rem;
    }

    .footer-social-image{
        height: 2rem;
        cursor: pointer;
    }

    .footer-contacts-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0.5rem 0;
    }

    .footer-contact{
        display: block;
        cursor: pointer;
        margin: 0.5rem 0;
    }

    .footer-contact-label{
        font-size: 1rem;
        color: var(--color10);
        font-weight: normal;
    }

    #footer-legal-area-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 1rem 0;
    }
    
    #footer-legal-area-title{
        font-weight: bold;
        font-size: 1.2rem;
        color: var(--color10);
        margin: 0.5rem 0;
    }

    #footer-end-container{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    #footer-end-payments-list{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem 0;
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer-end-payment-container{
        display: flex;
        padding: 0.1rem 0.5rem;
        background-color: white;
        margin: 0.5rem;
        align-items: center;
        justify-content: center;
        border-radius: 0.2rem;
    }

    .footer-end-payment{
        height: 1rem;
    }

    #footer-end{
        border-top: 0.1rem solid var(--color10);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    #footer-end-label{
        font-size: 0.8rem;
        color: var(--color10);
        font-weight: normal;
        margin-top: 0.5rem;
        margin-bottom: 5rem;
    }
}