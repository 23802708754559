@import url('../BaseStyle.css');

@media (min-width: 1025px){

    #alert{
        position: fixed;
        display: block;
        max-width: 20rem;
        width: 80vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;
        background-color: var(--color10);
        border-radius: 0.2rem; /*TEST border radius desktop*/
        border: 0 transparent;
    }

    #alert-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2vw;
        box-sizing: border-box;
        width: 100%;
    }

    #alert-container1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    #alert-container2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
    }

    #alert-container3-1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2vw;
        width: 100%;
    }

    #alert-container3-2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        height: 6rem;
        width: 100%;
    }

    #alert-title{
        color: var(--color6);
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
    }

    #alert-cross{
        width: 1.4rem;
        cursor: pointer;
    }

    #alert-image{
        height: 5rem;
    }

    #alert-text{
        color: var(--color6);
        font-size: 1rem;
        text-align: center;
    }

    .alert-button{
        background-color: var(--color6);
        font-size: 1rem;
        height: 2.5rem;
        border-radius: 0.2rem; /*TEST border radius desktop*/
        cursor: pointer;
    }

}